<template>
  <section
    v-if="!hideComp"
    class="j-services-product-recommend"
    :class="[
      `horizontal-recommend-${uniqueListType}`,
      `j-${code}`,
      `recommend-type-${listType}`,
    ]"
  >
    <div
      class="recommend-goodsd j-da-event-box"
      :data-is-fault-tolerant="faultTolerant"
      :data-dataType="dataType"
      :code="code"
      :data-title="analysisInfo.recommendName"
      :data-active-from="
        titleConfig.enValue.toLocaleLowerCase().split(' ').join('_')
      "
      :data-style="goodsItemConfig.isQuickView ? 'popup' : 'detail'"
      :data-poskey="[poskey || newPoskey, 'imageLabel'].join()"
      :data-traceid="traceid"
      :data-src-module="goodsItemConfig.srcModule"
      :data-src-identifier="goodsItemConfig.srcIdentifier"
      :data-module="!goodsItemConfig.isModule ? '' : 'module'"
    >
      <!-- title -->
      <h2
        v-if="titleConfig.title && titleConfig.isTitleCenter"
        class="goodsd-module-h"
        :class="[titleConfig.isTitleCenter ? 'module-center-h' : '']"
        tabindex="0"
        role="title"
        :aria-label="titleConfig.title"
        v-html="titleConfig.title"
      ></h2>
      <h2
        v-if="titleConfig.title && !titleConfig.isTitleCenter"
        class="recommend-title"
        :class="[
          titleConfig.showBottomLine ? 'recommend-title_show-bottom-line' : '',
        ]"
        tabindex="0"
        role="title"
        :aria-label="titleConfig.title"
      >
        <label>
          <span v-html="titleConfig.title"></span>
          <template
            v-if="
              titleConfig.showListNum &&
                recommendList &&
                recommendList.length > 0
            "
          >({{ titleConfig.listNum || (recommendList && recommendList.length) }})</template>
        </label>
      </h2>
      <!-- title -->
      <ProductListHolder
        v-if="recommendList == null"
        :num="swiperConfig.slidesPerView || 5"
        :column="swiperConfig.slidesPerView || 5"
        :brand="IS_RW ? 'romwe' : 'shein'"
        type="horizontal"
      />
      <div style="position: relative;">
        <swiper-container
          v-show="recommendList && limmitRecommendItems.length"
          ref="RecommendSwiperRef"
          init="false"
          class="recommend-swiper-container"
          destroy-on-disconnected="false"
          :dir="GB_cssRight ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(productItem, index) in limmitRecommendItems"
            :key="`${index}_${productItem.goods_id}`"
            class="recommend-swiper-slide"
          >
            <productItem
              ref="productItem"
              :key="`${index}-${productItem.goods_id}`"
              class="swiper-slide"
              :config="productItemShowConfig"
              :product-design-width="productDesignWidth"
              :product-item="productItem"
              :product-item-index="index"
              :goods-item-info="goodsItemInfo"
              :language="itemLanguage"
              :constant-data="constantData"
              :bottom-info-style="bottomInfoStyle"
            />
          </swiper-slide>
          <swiper-slide v-if="$slots?.endslide">
            <!-- 目前wishlist用到 -->
            <slot name="endslide"></slot>
          </swiper-slide>
        </swiper-container>
        <div
          v-if="recommendList && limmitRecommendItems.length"
          class="recommend-prev swiper-button-prev j-r-prev swiper-button-disabled"
          style="top: 45%"
          tabindex="0"
          aria-label="prev page"
        >
          <Icon 
            name="sui_icon_more_left_18px" 
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
        <div
          v-if="recommendList && limmitRecommendItems.length"
          class="recommend-next swiper-button-next j-r-next"
          style="top: 45%"
          tabindex="0"
          aria-label="next page"
        >
          <Icon 
            name="sui_icon_more_right_18px" 
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import productRecommend from '../index'
// import { logicMap } from '../emarsys/config'
import recommendMixin from './mixin'
import ProductListHolder from 'public/src/pages/components/ccc/base/ProductListHolder.vue'
const { IS_RW, GB_cssRight } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
import mitt from 'mitt'
import { Icon } from '@shein-aidc/icon-vue3'
import { register as swiperRegister } from 'swiper/element'
import { Navigation } from 'swiper/modules'
typeof window !== 'undefined' && swiperRegister()
const { PUBLIC_CDN } = gbCommonInfo

export default {
  components: {
    ProductListHolder,
    Icon
  },
  mixins: [recommendMixin],
  props: {
    /**
     * @param {String} code 推荐位标示，也用于ga埋点获取 页面类型判断
     */
    code: {
      type: String,
      default: '',
    },
    /**
     * @param {String} listType 推荐位列表标示，拼上时间戳，绑定于dom上，用于通过类名查找子集
     */
    listType: {
      type: String,
      default: '',
    },
    /**
     * @param {Array} filterGoodsIdList 需要过滤的商品Id
     */
    filterGoodsIdList: {
      type: Array,
      default() {
        return []
      },
    },
    /**
     * @param {String} poskey 新的实验
     */
    newPoskey: {
      type: String,
      default: '',
    },
    /**
     * @param {String} poskey 实验posKey
     */
    poskey: {
      type: String,
      default: '',
    },
    /**
     * 标题配置
     * @param {String} title 推荐位标题（多语言）
     * @param {String} enValue 推荐位标题 （英文）
     * @param {String} isTitleCenter // 标题是否居中展示
     * @param {String} showListNum // 是否展示列表页数量，默认获取recommendList长度，如传入listNum 并且有值，则展示listNum数值
     * @param {String} listNum // 列表页数量，仅在showListNum为true有效
     * @param {Boolean} showBottomLine // 是否需要展示分割线
     *
     * {
     *    title: '',
     *    enValue: '',
     *    isTitleCenter: '',
     *    showListNum: '',
     *    listNum: '',
     *    showBottomLine: ''
     *  }
     */
    titleConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    /**
     *  @param {Array} abtParamsMatchConfig abt解析配置
     *    @param {String} type 解析类型  ['emarsys', 'request']
     *    @param {RegExp} matchRule 解析规则
     *    @param {String | Function} url 当Type为request时处理url字段，请求接口地址
     *    @param {Object ｜ Function} params 接口请求参数
     *    @param {Boolean} notFaultTolerance 当前配置不需要走容错逻辑
     *    @param {Number} timeout 冗错 超时时间, 一般设置 10000
     * {
     *      type: 'request',
     *      matchRule: /^is_pde=3/,
     *      url: `${ langPath }/product/recommend/getDetailRecommendProducts`,
     *      url: ({abtResult = {}} = {}) => {
     *          return url
     *      },
     *      params: ({abtResult = {}} = {}) => {
     *          return  params
     *      }
     *      params: {
     *          cat_id,
     *          goods_id,
     *          limit: 100,
     *          rule_id: /rule_id=([^&]+)/,
     *          abtInfo: ({abtResult = {}} = {}) => {
     *              return abtResult.expid
     *          }
     *      }
     *  }
     *  @param {Object} paramsMatchConfig 无abt时，解析配置
     *    @param {String} type 解析类型  ['emarsys', 'request']
     *    @param {String} url 当Type为request时处理url字段，请求接口地址
     *    @param {Object} params 接口请求参数
     *    @param {Boolean} notFaultTolerance 当前配置不需要走容错逻辑
     *
     *  @param {Object} faultTolerance 容错配置
     *    @param {String} mode 固定 faultTolerance
     *    @param {String} type 解析类型, 枚举['emarsys', 'request']
     *    @param {String} url 当Type为request时处理url字段，请求接口地址
     *    @param {Object} params 接口请求参数
     *    @param {Number} timeout 冗灾 超时时间, 一般设置 30000
     *
     */
    config: {
      type: Object,
      default() {
        return {}
      },
    },
    /**
     * @param {Object} analysisInfo 埋点信息配置
     *   @param {String} recommendName 推荐位名称，用于ga sa 埋点上报
     *   @param {String} from 推荐位来源 用于sa埋点
     *   @param {String} scrollContainer 滚动容器(选择器)，默认window
     */
    analysisInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    /**
     * @param {Object} goodsItemConfig 商品Item 配置
     *   @param {Boolean} isQuickView 点击商品 快速查看
     *   @param {FUnction} handleItemClick 商品点击回调，当isQuickView为true时有效
     *   @param {Boolean} showRank 是否显示评论
     *   @param {Boolean} showMultiColor 是否显示多颜色
     *   @param {Boolean} showPromotion 是否展示促销
     *   @param {Boolean} showReducePrice 是否展示降价提醒
    */
    goodsItemConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    /**
     * @param {Object} swiperConfig swiper配置
     *   @param {Number} spaceBetween slide之间的距离
     *   @param {Number} slidesPerView slider容器能够同时显示的slides数量
     *   @param {Number} slidesPerGroup 定义slides的数量多少为一组
     */
    swiperConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    /**
     * @param {Object} loadConfig 加载配置
     *   @param {Number} totalLimit 推荐总数
     */
    loadConfig: {
      type: Object,
      default() {
        return {
          totalLimit: 25,
        }
      },
    },
    /** 容器设计稿宽度（用于裁切） */
    containerDesignWidth: {
      type: [String, Number],
      default: 0,
    },
    
    bottomInfoStyle: {
      type: Object,
      default: () => ({ minHeight: '98px' }),
    }
  },
  data() {
    return {
      IS_RW,
      GB_cssRight,
      hideComp: false,
      abtInfo: '',
      loading: true,
      productRecommendServices: '',
      recommendList: null,
      faultTolerant: 0,
      dataType: '',
      traceid: '',
    }
  },
  computed: {
    uniqueListType() {
      return `${this.listType}_${Date.now()}`
    },
    productDesignWidth() {
      if (!this.containerDesignWidth) {
        return 0
      }
      const count = this.swiperConfig?.slidesPerView || 5
      return Math.floor((Number(this.containerDesignWidth) / count) - 8)
    },
    limmitRecommendItems() {
      if (this.recommendItems && this.recommendItems.length) {
        return this.recommendItems.slice(0, this.loadConfig.totalLimit)
      }
      return this.recommendItems
    },
  },
  watch: {
    filterGoodsIdList: {
      handler(val) {
        let list = this.productRecommendServices.filterList(
          this.recommendList || [],
          val
        )
        this.recommendList = list
      }
    },
    recommendList: {
      handler(val) {
        if (!val || val.length <= 0) return
        window.setTimeout(() => {
          this.initSwiper()
        })
      }
    },
  },
  created() {
    this.initRecommend()
  },
  mounted() {
    this.traceid = gbExposeTraceid('getComponent', {
      componentName: this.code,
    })
    this.$emit('mounted')

    if (!window.appEventCenter) {
      window.appEventCenter = mitt()
    }
    window.appEventCenter.on('togerher-add-quickView', ({ goods_id, cb }) => {
      this.recommendList = this.recommendList.filter(f => f.goods_id !== goods_id)
      cb && cb(this.recommendList)
    })
  },
  beforeUnmount () {
    this.swiper?.destroy()
  },
  methods: {
    initRecommend() {
      const { abtParamsMatchConfig = [], paramsMatchConfig = {} } = this.config
      let { faultTolerance = null } = this.config
      this.productRecommendServices = new productRecommend({
        abtParamsMatchConfig,
        paramsMatchConfig,
        faultTolerance,
        filterGoodsIdList: this.filterGoodsIdList,
      })

      // 单独初始化 analysis
      this.productRecommendServices.initAnalysis({
        recommendType: 'swiper',
        layout: 'horizontal-recommend',
        listType: this.uniqueListType,
        scrollContainer: this.analysisInfo.scrollContainer,
        getExposeElements: this._getScrollExposeDoms || null,
        poskey: this.poskey || this.newPoskey,
        code: this.code,
        recommendName: this.analysisInfo.recommendName || '',
        position: this.analysisInfo.position || 1,
        from: this.analysisInfo.from || 'recommend',
      })
    },
    // 初始化swiper
    initSwiper() {
      let prevClassName =
        '.horizontal-recommend-' + this.uniqueListType + ' .j-r-prev'
      let nextClassName =
        '.horizontal-recommend-' + this.uniqueListType + ' .j-r-next'

      const swiperEl = this.$refs['RecommendSwiperRef']

      const swiperConfig = {
        modules: [Navigation],
        grid: {
          rows: 1,
        },
        direction: 'horizontal',
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 10,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper自己或子元素时，自动初始化swiper
        roundLengths: true,
        allowTouchMove: false,
        navigation: {
          nextEl: nextClassName,
          prevEl: prevClassName
        },
        injectStylesUrls: [
          `${PUBLIC_CDN}/she_dist/libs/swiper/modules/navigation-element.min.css`,
        ],
        on: {},
        ...this.swiperConfig,
      }
      swiperConfig.on = {
        slideChangeTransitionEnd: (swiper) => {
          if (typeof GB_SHEIN_lazyLoadInstance != 'undefined')
            GB_SHEIN_lazyLoadInstance.update()
          var beginIndex = swiper.activeIndex
          this.productRecommendServices.analysis.preExposeProductList =
            this.recommendList?.slice(beginIndex, beginIndex + 6)
          window.dispatchEvent(new Event('scroll'))
        },
        init: () => {
          // set pre expose product list
          this.productRecommendServices.analysis.preExposeProductList =
            this.recommendList?.slice(0, Number(swiperConfig.slidesPerView))
          window.dispatchEvent(new Event('scroll'))
        },
        update: () => {
          this.productRecommendServices.analysis.preExposeProductList =
            this.recommendList?.slice(0, Number(swiperConfig.slidesPerView))
          window.dispatchEvent(new Event('scroll'))
        },
        slideNextTransitionEnd: () => {
          // 购物车收藏事件
          this.listType == 'cart-saved-items' && this.$emit('moveFavortieItemEvt')
        }
      }
      if (typeof swiperConfig.slidesPerView !== 'number')
        console.error('swiperConfig.slidesPerView is not number')
      if (swiperConfig.slidesPerView !== swiperConfig.slidesPerGroup)
        console.error(
          'swiperConfig.slidesPerView Is not equal to swiperConfig.slidesPerGroup'
        )
      Object.assign(swiperEl, swiperConfig)
      swiperEl.initialize()
      this.swiper = swiperEl?.swiper
      
      if (typeof GB_SHEIN_lazyLoadInstance != 'undefined')
        GB_SHEIN_lazyLoadInstance.update()
    },
    async doRecommend(fn) {
      await this.initItemAbt() // abt

      let { recommendInfo, abtInfo } =
        await this.productRecommendServices.doRecommend({
          newPoskey: this.newPoskey,
          posKey: this.poskey,
          pageKey: this.config.sceneConfig?.pageKey || '',
          limit: this.loadConfig.totalLimit || 25, // 分页只对自有推荐有效
          itemConfig: this.productItemShowConfig,
        })
      let list = this.productRecommendServices.filterList(
        recommendInfo.products || [],
        this.filterGoodsIdList
      )
      this.abtInfo = abtInfo
      this.faultTolerant = recommendInfo.isFaultTolerant
      this.dataType = recommendInfo.dataType
      const _newList = this.newProductCard
        ? await this._handleProductsNewCard(list)
        : await this._handleProducts(list, recommendInfo.withAtomic)

      this.recommendList = _newList
      this.hideComp = this.recommendList.length == 0
      this.$emit('recommendListReady', {
        code: this.code,
        list: this.recommendList,
        faultTolerant: this.faultTolerant,
        recommendInfo,
      })

      fn && fn()
    },
  },
  emits: ['recommendListReady', 'mounted', 'moveFavortieItemEvt'],
}
</script>

<style lang="less">
.recommend-goodsd {
  .goodsd-module-h {
    font-size: 28px;
    /*rw:begin*/
    font-family: Adieu-Bold, Adieu;
  }
  .recommend-prev,
  .recommend-next {
    width: 32px;
    height: 32px;
    background: rgba(255,255,255,.94);
    border-radius: 18px;
    font-size: 12px;
    text-align: center;
    vertical-align: unset;

    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: normal;

    pointer-events: auto !important; /* stylelint-disable-line declaration-no-important */
    cursor: pointer;
    font-size: 18px;
    color: #222;
    user-select: none;
  }
  .recommend-prev {
    .left(5px);
  }
  .recommend-next {
    .right(5px);
  }
}
</style>
